import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { useNavigate, useParams } from 'react-router-dom';
import { editQuestion, getOneQuestion, getAllLevel, getQuestionMl } from '../../services/quiz-api';
import '../css/login.css';
import '../css/custom.css';
import { levels } from '../../utils/levels';
import { types } from '../../utils/type';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import arrowBackIcon from '../icon/arrow-back.svg';
import { languages } from '../../utils/constants';

const EditQuestion = () => {

  const navigate = useNavigate();
  const { quizId, id } = useParams();
  const [previewContent, setPreviewContent] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [state, setState] = useState({
    title: "",
    type: "",
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    correctAnswer: "",
    levelId: levels[0].id,
    image: "",
    video: "",
    languageId: "0",
  });
  // const [levels, setLevels] = useState([])

  const onSubmit = async (event) => {
    event.preventDefault();
    console.log("Submit State ", state);
    //check validation
    if (state.title) {

      if (tempImage === state.image)
        state.image = "";
      //call services api
      await editQuestion(id, {
        ...state
      }).then(res => {
        toast("Data has been successfully updated.")
        //navigate(`/quiz/question/${quizId}`)
      }).catch(err => {
        console.log(err)
      })
    }
  }

  useEffect(() => {
    getOneQuestion(id).then(async res => {
      console.log("Res Question Edit ", res);
      setTempImage(res.image);
      setState(prevState => ({
        ...prevState,
        title: res.title,
        type: res.type,
        option_1: res.option_1,
        option_2: res.option_2,
        option_3: res.option_3,
        option_4: res.option_4,
        correctAnswer: res.correctAnswer,
        levelId: res.levelId,
        languageId: res.language_id,
      }))
      //setPreviewImage();

      const response = await fetch(res.image);
      const blob = await response.blob();
      const type = blob.type;
      if (type.startsWith('image/')) {
        setState(prevState => ({ ...prevState, video: '', image: res.image }));
        setPreviewContent(res.image);
      } else if (type.startsWith('video/')) {
        setState(prevState => ({ ...prevState, video: res.image, image: '' }));
        setPreviewContent(res.image);
      }

      console.log(state.correctAnswer)
    })
  }, [])

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (file.type.startsWith('image/')) {
          // If the file is an image, set the image state and preview image
          setState(prevState => ({ ...prevState, image: reader.result, video: '' }));
          setPreviewContent(reader.result);
          state.image = reader.result
        } else if (file.type.startsWith('video/')) {
          // If the file is a video, set the video state and preview video
          setState(prevState => ({ ...prevState, video: reader.result, image: '' }));
          setPreviewContent(reader.result);
          state.image = reader.result
        } else {
          // Handle other file types if needed
          console.error('Unsupported file type');
        }
      };

      reader.readAsDataURL(file);
    }
  };
  const handleRadioChange = (event) => {
    setState(prevState => ({ ...prevState, correctAnswer: event.target.value }));
  };

  const handleOnChange = (questionId, languageId) => {
    console.log("Front ", quizId);
    console.log("LanguageId ", languageId);
    getQuestionMl(questionId, languageId).then((res) => {
      console.log("res ", res);
      if (res != null) {
        console.log("inside if********");
        setState(prevState => ({
          ...prevState,
          title: res.title,
          type: res.type,
          option_1: res.option_1,
          option_2: res.option_2,
          option_3: res.option_3,
          option_4: res.option_4,
          correctAnswer: res.correctAnswer,
          levelId: res.levelId,
          languageId: res.language_id,
        }))
      } else {
        console.log("inside else********");
        setState(prevState => ({
          ...prevState,
          title: "",
          option_1: "",
          option_2: "",
          option_3: "",
          option_4: ""
        }))
      }

    }).catch((err) => {
      console.log(err);
    });
  }
  const handleLanguageChange = (event) => {
    const selectedLanguageId = event.target.value;

    setState(prevState => ({
      ...prevState,
      languageId: selectedLanguageId,
    }));

    handleOnChange(id, selectedLanguageId);
  };
  const handleTypeChange = (event) => {
    setState(prevState => ({
      ...prevState,
      type: event.target.value,
    }));
  };
  const handleLevelChange = (event) => {
    setState(prevState => ({
      ...prevState,
      levelId: event.target.value,
    }));
  };
  useEffect(() => {
    console.log("Question State current state ", state);
  })

  return (
    <>
      <ToastContainer />
      <Layout>
        <p onClick={() => navigate(-1)} className="back-pre"><img src={arrowBackIcon} alt="Back" /> Back</p>
        <div className="d-flex">
          <h2>Edit Question</h2>
        </div>

        <div className="authincation-content">
          <section className="login-part">
            <div className="container">
              <div className="login">
                <div className="login-card">
                  <div className="card">
                    <form onSubmit={onSubmit}>
                      <div className="card-body">
                        <div className="mb-4">
                          <label htmlFor="language" className="form-label">
                            Select Language
                          </label>
                          <select id="language" className="form-control" onChange={handleLanguageChange}>
                            {languages.map(language => (
                              <option value={language.id}>{language.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-4">
                          <label htmlFor="title" className="form-label">
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            placeholder="Enter title"
                            value={state.title}
                            onChange={(event) =>
                              setState(prevState => ({
                                ...prevState,
                                title: event.target.value,
                              }))
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="type" className="form-label">
                            Type
                          </label>
                          <div className='grid'>
                            {types &&
                              types.map((type, index) => (
                                <div key={type.id}>
                                  <label>
                                    <input
                                      type="radio"
                                      name="type"
                                      value={type.id}
                                      checked={state.type == type.id}
                                      onChange={handleTypeChange}
                                    />
                                    <span>
                                    {type.name}
                                    </span>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="mb-4">
                          <label htmlFor="levelId" className="form-label">
                            Level
                          </label>
                          <div className='grid'>
                            {levels &&
                              levels.map((level, index) => (
                                <div key={level.id}>
                                  <label>
                                    <input
                                      type="radio"
                                      name="level"
                                      value={level.id}
                                      checked={state.levelId == level.id}
                                      onChange={handleLevelChange}
                                    />
                                    <span>
                                    {level.name}
                                    </span>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="mb-4">
                          <label htmlFor="option_1" className="form-label">
                            Option 1
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="option_1"
                            placeholder="Enter option 1"
                            value={state.option_1}
                            onChange={(event) =>
                              setState(prevState => ({
                                ...prevState,
                                option_1: event.target.value,
                              }))
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="option_2" className="form-label">
                            Option 2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="option_2"
                            placeholder="Enter option 2"
                            value={state.option_2}
                            onChange={(event) =>
                              setState(prevState => ({
                                ...prevState,
                                option_2: event.target.value,
                              }))
                            }
                            autoComplete="off"
                          />
                        </div>
                        {String(state.type) === "4" && (
                          <>
                            <div className="mb-4">
                              <label
                                htmlFor="option_3"
                                className="form-label"
                              >
                                Option 3
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="option_3"
                                placeholder="Enter option 3"
                                value={state.option_3}
                                onChange={(event) =>
                                  setState(prevState => ({
                                    ...prevState,
                                    option_3: event.target.value,
                                  }))
                                }
                                autoComplete="off"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="option_4"
                                className="form-label"
                              >
                                Option 4
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="option_4"
                                placeholder="Enter option 4"
                                value={state.option_4}
                                onChange={(event) =>
                                  setState(prevState => ({
                                    ...prevState,
                                    option_4: event.target.value,
                                  }))
                                }
                                autoComplete="off"
                              />
                            </div>
                          </>
                        )}
                        <div className="mb-4 manage-option">
                          <div class="form-check">
                            <label
                              class="form-check-label"
                              for="exampleRadios1"
                            >
                              <input
                                type="radio"
                                name="correctAnswer"
                                value="1"
                                checked={String(state.correctAnswer) === "1"}
                                onChange={handleRadioChange}
                                class="form-check-input"
                                id="exampleRadios1"
                              />
                              Option 1
                            </label>
                          </div>

                          <div class="form-check">
                            <label
                              class="form-check-label"
                              for="exampleRadios2"
                            >
                              <input
                                type="radio"
                                name="correctAnswer"
                                value="2"
                                checked={String(state.correctAnswer) === "2"}
                                onChange={handleRadioChange}
                                class="form-check-input"
                                id="exampleRadios2"
                              />
                              Option 2
                            </label>
                          </div>
                          {String(state.type) === "4" && (
                            <>
                              <div class="form-check">
                                <label
                                  class="form-check-label"
                                  for="exampleRadios3"
                                >
                                  <input
                                    type="radio"
                                    name="correctAnswer"
                                    value="3"
                                    checked={
                                      String(state.correctAnswer) === "3"
                                    }
                                    onChange={handleRadioChange}
                                    class="form-check-input"
                                    id="exampleRadios3"
                                  />
                                  Option 3
                                </label>
                              </div>
                              <div class="form-check">
                                <label
                                  class="form-check-label"
                                  for="exampleRadios4"
                                >
                                  <input
                                    type="radio"
                                    name="correctAnswer"
                                    value="4"
                                    checked={
                                      String(state.correctAnswer) === "4"
                                    }
                                    onChange={handleRadioChange}
                                    class="form-check-input"
                                    id="exampleRadios4"
                                  />
                                  Option 4
                                </label>
                              </div>
                            </>
                          )}
                        </div>
                        <div class="mb-4">
                          <label for="formFile" class="form-label">
                            Select File
                          </label>
                          <input
                            class="form-control"
                            type="file"
                            id="formFile"
                            onChange={handleFileChange}
                          />
                          {previewContent && (
                            <div className="content-container">
                              {state.image && (
                                <img
                                  src={previewContent}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "200px",
                                    marginTop: "10px",
                                  }}
                                />
                              )}
                              {state.video && (
                                <video
                                  controls
                                  width="200"
                                  style={{ marginTop: "10px" }}
                                >
                                  <source
                                    src={previewContent}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="form-login-button pt-4">
                          <button type="submit">Save</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}

export default EditQuestion